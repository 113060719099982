import AsyncStorage from "@react-native-async-storage/async-storage";
import { useContext } from "react";
import { useQueryClient } from "react-query";
import { AuthContext } from "../components/App/AuthContext";

/**
 * Clean up user data on device
 */
export function useInvalidateLogin() {
  const authContext = useContext(AuthContext);
  const queryClient = useQueryClient();

  return () => {
    queryClient.clear();
    AsyncStorage.setItem("@expedibox:deviceToken", "");
    AsyncStorage.setItem("@expedibox:expoPushToken", "");
    authContext.setDeviceToken("");
  };
}

import { useNavigation } from "@react-navigation/core";
import React, { useState } from "react";
import {
  Image,
  StyleSheet,
  View,
  Text,
  TextInput,
  KeyboardAvoidingView,
  Platform,
} from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useMutation } from "react-query";
import { CreateOTPMutationFn } from "../../api/mobileApi";
import { useIsValidEmail } from "../../hooks/useIsValidEmail";
import bike from "./bike_ride.png";

export const Step1: React.FC = () => {
  const navigation = useNavigation();
  const [email, setEmail] = useState("");
  const isValidEmail = useIsValidEmail(email);

  const mutation = useMutation(CreateOTPMutationFn, {
    onSuccess: () => navigation.navigate("Step2", { email }),
    onError: () => {},
  });

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
    >
      <View style={styles.loginView}>
        <Image source={bike} style={{ width: 300, height: 260 }} />

        <Text style={styles.title}>Entrez votre courriel</Text>

        <Text style={styles.subtitle}>
          Vous recevrez un code pour vous connecter.
        </Text>

        {mutation.error && (
          <Text style={styles.error}>
            Adresse introuvable, veuillez réessayer ou contacter le gestionnaire
            de votre immeuble.
          </Text>
        )}

        <TextInput
          style={styles.input}
          placeholder="Courriel"
          value={email}
          onChangeText={(text) => setEmail(text)}
          autoCompleteType="email"
          keyboardType="email-address"
          textContentType="emailAddress"
          autoCapitalize="none"
          autoFocus
        />
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={
              isValidEmail || mutation.isLoading
                ? styles.button
                : styles.buttonDisabled
            }
            onPress={() => mutation.mutate({ email })}
            disabled={!isValidEmail || mutation.isLoading}
          >
            <Text style={styles.buttonText}>Continuer</Text>
          </TouchableOpacity>
        </View>
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  loginView: {
    marginTop: 25,
    textAlign: "center",
    width: 300,
    marginHorizontal: "auto",
  },
  error: {
    backgroundColor: "#fffbe6",
    marginTop: 15,
    paddingHorizontal: 15,
    paddingVertical: 8,
    textAlign: "center",
    borderWidth: 1,
    borderColor: "#ffecb5",
    color: "#664d03",
  },
  title: {
    marginTop: 50,
    fontSize: 20,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 14,
    marginVertical: 5,
    textAlign: "center",
  },
  input: {
    marginVertical: 25,
    borderWidth: 1,
    fontSize: 16,
    height: 40,
    paddingHorizontal: 10,
    width: 300,
    backgroundColor: "#fff",
    borderRadius: 5,
    textAlign: "center",
  },
  buttonContainer: {
    marginVertical: 10,
  },
  button: {
    backgroundColor: "#0d99d6",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  buttonDisabled: {
    backgroundColor: "#d0e1e8",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  buttonText: {
    fontSize: 16,
    color: "#fff",
    fontWeight: "bold",
  },
});

import { get, patch, post, _delete } from "../utils/fetch";

const API_URL_DEV = "http://api.expediboxdev.com:8000";
const API_URL_PROD = "https://api.expedibox.com";
const API_URL = __DEV__ ? API_URL_DEV : API_URL_PROD;

export interface PaginatedResponse<T> {
  results: T[];
  next: string;
  previous: string;
  count: number;
}

export interface Door {
  position: string;
  status: string;
  dimension: string;
}

export interface Locker {
  id: string;
  doors: Door[];
}

export const GetLockersQueryFn = (
  deviceToken: string
): Promise<PaginatedResponse<Locker>> => {
  return get(`${API_URL}/lockers?expedibike=true`, {
    headers: { "X-Device-Token": deviceToken },
  });
};

export interface CreateReservationMutationData {
  deviceToken: string;
  door_position: string;
  locker_id: string;
  notify_sender: boolean;
  notify_receiver: boolean;
}

export const CreateReservationMutationFn = (
  data: CreateReservationMutationData
) => {
  return post(`${API_URL}/reservations`, data, {
    headers: { "X-Device-Token": data.deviceToken },
  });
};

export interface Reservation {
  id: string;
  door: Door;
  deposit_code: string;
  deposit_at: Date;
  pickup_code: string;
  pickup_at: string;
}

export const GetActiveReservationsQueryFn = (
  deviceToken: string
): Promise<PaginatedResponse<Reservation>> => {
  return get(`${API_URL}/reservations?active=true`, {
    headers: { "X-Device-Token": deviceToken },
  });
};

export interface DeleteReservationMutationData {
  deviceToken: string;
  reservationId: string;
}

export const DeleteReservationMutationFn = (
  data: DeleteReservationMutationData
) => {
  return _delete(`${API_URL}/reservations/${data.reservationId}`, {
    headers: { "X-Device-Token": data.deviceToken },
  });
};

import { AppState } from "react-native";
import { focusManager } from "react-query";

focusManager.setEventListener((setFocus: any) => {
  const handleAppStateChange = (appState: any) => {
    setFocus(appState === "active");
  };

  AppState.addEventListener("change", handleAppStateChange);

  return () => {
    AppState.removeEventListener("change", handleAppStateChange);
  };
});

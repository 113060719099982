import React from "react";
import styled from "styled-components/native";
import { Button } from "../../components/Button/Button";
import { useNavigation } from "@react-navigation/core";
import { useDeviceToken } from "../../components/App/AuthContext";
import { useQuery } from "react-query";
import { GetActiveReservationsQueryFn, Reservation } from "../../api/publicApi";
import { useInvalidateLoginOnError } from "../../hooks/useInvalidateLoginOnError";
import { Image, ActivityIndicator, FlatList, View, Text } from "react-native";
import { EvilIcons, MaterialIcons } from "@expo/vector-icons";
import { TouchableOpacity } from "react-native-gesture-handler";
import biking from "./biking.png";

const Container = styled.View`
  margin-horizontal: auto;
  margin-top: 60px;
  padding-horizontal: 20px;
`;

const Empty = styled.Text`
  margin-top: 50px;
`;

const Header = styled.Text`
  margin-bottom: 50px;
  text-align: center;
`;

interface ReservationItemProps {
  reservation: Reservation;
}

function ReservationItem({ reservation }: ReservationItemProps) {
  const navigation = useNavigation();

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        borderBottomColor: "#e0e0e0",
        borderBottomWidth: 1,
        minWidth: 300,
      }}
    >
      <View>
        <MaterialIcons
          name="pedal-bike"
          size={64}
          color="#0d99d6"
          style={{ padding: 20 }}
        />
      </View>
      <View>
        <Text>
          <strong style={{ textTransform: "capitalize" }}>
            {reservation.door.dimension}
          </strong>{" "}
          {reservation.door.status === "reserved"
            ? "réservé"
            : "en cours d'utilisation"}
        </Text>
        <Text style={{ letterSpacing: 8, fontSize: 24, paddingTop: 10 }}>
          {reservation.door.status === "reserved"
            ? reservation.deposit_code
            : reservation.pickup_code}
        </Text>
      </View>
      <View>
        {reservation.door.status === "reserved" && (
          <TouchableOpacity
            onPress={() =>
              navigation.navigate("ConfirmDeleteReservation", {
                reservationId: reservation.id,
                dimension: reservation.door.dimension,
              })
            }
          >
            <EvilIcons
              name="close-o"
              size={44}
              color="red"
              style={{ padding: 20 }}
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}

export const Home: React.FC = () => {
  const navigation = useNavigation();
  const deviceToken = useDeviceToken();
  const logoutOnError = useInvalidateLoginOnError("Une erreur est survenue");

  const { isLoading, data, refetch } = useQuery(
    ["reservations", { active: true }],
    () => GetActiveReservationsQueryFn(deviceToken),
    { onError: logoutOnError }
  );

  return (
    <Container>
      {isLoading && (
        <ActivityIndicator animating size="large" color="#0D99D6" />
      )}

      {!isLoading && data && (
        <FlatList
          refreshing={isLoading}
          contentContainerStyle={{ alignItems: "center" }}
          onRefresh={() => refetch()}
          data={data && data.results}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => <ReservationItem reservation={item} />}
          ListHeaderComponent={
            <>
              {data && !!data.results.length && (
                <Header>
                  Pour récupérer ou terminer votre réservation, veuillez entrer
                  son code sur l’écran dans la salle des vélos.
                </Header>
              )}
            </>
          }
          ListEmptyComponent={
            <>
              <Image source={biking} style={{ width: 263, height: 171 }} />
              <Empty>Aucune réservation en cours</Empty>
            </>
          }
          ListFooterComponent={
            <Button
              style={{ marginTop: 30 }}
              onPress={() => navigation.navigate("SelectBike")}
              title="Réserver un vélo"
            />
          }
        />
      )}
    </Container>
  );
};

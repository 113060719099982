import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components/native";
import { Expedibike } from "../Expedibike";
import { AuthContext } from "./AuthContext";
import "react-native-gesture-handler";
import "./setFocus";

const queryClient = new QueryClient();

const lightTheme = {
  primary: "#0D99D6",
  info: "#37DDB5",
};

export const App: React.FC = () => {
  const [deviceToken, setDeviceToken] = useState<string | null>();
  const [preloading, setPreloading] = useState(true);

  useEffect(() => {
    AsyncStorage.getItem("@expedibox:deviceToken").then((value) => {
      setDeviceToken(value);
      setPreloading(false);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ deviceToken, setDeviceToken }}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={lightTheme}>
          <Expedibike />
        </ThemeProvider>
      </QueryClientProvider>
    </AuthContext.Provider>
  );
};

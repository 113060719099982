import Constants from "expo-constants";
import * as Device from "expo-device";
import React, { useContext, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { useMutation } from "react-query";
import { Ionicons } from "@expo/vector-icons";
import { CreateTokenMutationFn } from "../../api/mobileApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AuthContext } from "../../components/App/AuthContext";
import { useRoute } from "@react-navigation/native";

interface RouteParams {
  deviceUUID: string;
  email: string;
}

export const Step2: React.FC = () => {
  const authContext = useContext(AuthContext);
  const [otp, setOtp] = useState("");

  const route = useRoute();
  const { deviceUUID, email } = route.params as RouteParams;

  const mutation = useMutation(CreateTokenMutationFn, {
    onSuccess: (data) => {
      AsyncStorage.setItem("@expedibox:deviceToken", data.token);
      authContext.setDeviceToken(data.token);
    },
  });

  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        Entrez le code de vérification que vous avez reçu par courriel.
      </Text>

      {mutation.error && (
        <Text style={styles.error}>
          Ce code est invalide. Veuillez réessayer.
        </Text>
      )}

      <TextInput
        style={styles.input}
        placeholder="1234"
        value={otp}
        onChangeText={(text) => setOtp(text)}
        autoCompleteType="password"
        keyboardType="number-pad"
        textContentType="oneTimeCode"
      />

      <View style={styles.buttonContainer}>
        <TouchableOpacity
          style={otp ? styles.button : styles.buttonDisabled}
          onPress={() =>
            mutation.mutate({
              device_uuid: deviceUUID,
              email,
              code: otp,
              brand: Device.brand,
              manufacturer: Device.manufacturer,
              modelName: Device.modelName,
              osVersion: Device.osVersion,
            })
          }
          disabled={!otp || mutation.isLoading}
        >
          <Text style={styles.buttonText}>Vérifier</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    paddingHorizontal: 10,
    marginHorizontal: "auto",
    marginTop: 100,
  },
  error: {
    backgroundColor: "#fffbe6",
    width: 300,
    paddingHorizontal: 15,
    paddingVertical: 8,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    marginVertical: 15,
    textAlign: "center",
    paddingHorizontal: 30,
  },
  input: {
    marginVertical: 15,
    borderWidth: 1,
    height: 30,
    borderColor: "#d9d9d9",
    paddingHorizontal: 10,
    width: 300,
    backgroundColor: "#fff",
    borderRadius: 5,
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: 15,
  },
  button: {
    backgroundColor: "#0d99d6",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  buttonDisabled: {
    backgroundColor: "#d0e1e8",
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
  },
  buttonText: {
    fontSize: 16,
    color: "#fff",
    fontWeight: "bold",
  },
});

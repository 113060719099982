import { createContext, useContext } from "react";

interface AuthContextData {
  deviceToken?: string | null;
  setDeviceToken: (deviceToken: string) => any;
}

export const AuthContext = createContext<AuthContextData>({
  deviceToken: null,
  setDeviceToken: () => {},
});

export const useDeviceToken = (): string => {
  const authContext = useContext(AuthContext);
  return authContext.deviceToken || "";
};

const DEFAULT_CONFIG = {
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

async function http<T>(path: string, config: RequestInit): Promise<T> {
  const request = new Request(path, config);
  const response = await fetch(request);

  if (!response.ok) {
    throw new Error(response.status.toString());
  }

  return response.json().catch(() => ({}));
}

export async function get<T>(path: string, config?: RequestInit): Promise<T> {
  const init = { method: "get", ...DEFAULT_CONFIG, ...config };
  return await http<T>(path, init);
}

export function post<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = {
    method: "post",
    body: JSON.stringify(body),
    ...DEFAULT_CONFIG,
    ...config,
  };
  // overwrite headers for simplicity for the caller (but removes some flexibility)
  Object.assign(init.headers, DEFAULT_CONFIG.headers);
  return http<U>(path, init);
}

export function patch<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = {
    method: "patch",
    body: JSON.stringify(body),
    ...DEFAULT_CONFIG,
    ...config,
  };
  // overwrite headers for simplicity for the caller (but removes some flexibility)
  Object.assign(init.headers, DEFAULT_CONFIG.headers);
  return http<U>(path, init);
}

export async function put<T, U>(
  path: string,
  body: T,
  config?: RequestInit
): Promise<U> {
  const init = {
    method: "put",
    body: JSON.stringify(body),
    ...DEFAULT_CONFIG,
    ...config,
  };
  return await http<U>(path, init);
}

export async function _delete<T, U>(
  path: string,
  config?: RequestInit
): Promise<U> {
  const init = {
    method: "DELETE",
    ...config,
  };

  return await http<U>(path, init);
}

import { useInvalidateLogin } from "./useInvalidateLogin";

/**
 * Clean up user data on device if the token is invalid.
 * Also, alerts an optional message for any type of errors.
 */
export function useInvalidateLoginOnError(message?: string) {
  const invalidateLogin = useInvalidateLogin();

  return (error: Error) => {
    if (error.message === "403") {
      invalidateLogin();
    }
    if (message) {
      alert(message);
    }
  };
}

import { get, patch, post, _delete } from "../utils/fetch";

const API_URL_DEV = "http://mobileapi.expediboxdev.com:8000";
const API_URL_PROD = "https://mobileapi.expedibox.com";
const API_URL = __DEV__ ? API_URL_DEV : API_URL_PROD;

export interface CreateOTPMutationData {
  email: string;
}

export const CreateOTPMutationFn = (data: CreateOTPMutationData) => {
  return post(`${API_URL}/otp`, data);
};

export interface CreateTokenMutationData {
  code: string;
  device_uuid: string;
  email: string;
  app?: string | null;
  brand?: string | null;
  manufacturer?: string | null;
  modelName?: string | null;
  osVersion?: string | null;
}

export interface CreateTokenMutationResponse {
  token: string;
}

export const CreateTokenMutationFn = (
  data: CreateTokenMutationData
): Promise<CreateTokenMutationResponse> => {
  return post(`${API_URL}/device-token`, { ...data, app: "expedibike" });
};

export const DeleteDeviceTokenMutationFn = (deviceToken: string) => {
  return _delete(`${API_URL}/device-token`, {
    headers: { "X-Device-Token": deviceToken },
  });
};

export interface CreateAuthMutationData {
  email: string;
  token: string;
}

export const CreateAuthMutationFn = (data: CreateAuthMutationData) => {
  return post(`${API_URL}/auth`, data);
};

export interface Location {
  name: string;
  street1: string;
  street2: string;
  city: string;
  state: string;
  country: string;
  zip_code: string;
}

export interface Locker {
  location: Location;
  name: string;
}

export interface Member {
  id: number;
  notify_email: boolean;
  notify_sms: boolean;
  notify_push: boolean;
}

export interface PaginatedResponse<T> {
  results: T[];
  next: string;
  previous: string;
  count: number;
}

export const CreateExpoPushTokenMutationFn = (
  deviceToken: string,
  expoPushToken: string
) => {
  return post(
    `${API_URL}/expo-push-token`,
    { token: expoPushToken },
    { headers: { "X-Device-Token": deviceToken } }
  );
};

export const DeleteExpoPushTokenMutationFn = (deviceToken: string) => {
  return _delete(`${API_URL}/expo-push-token`, {
    headers: { "X-Device-Token": deviceToken },
  });
};

export const GetMemberQueryFn = (deviceToken: string): Promise<Member> => {
  return get(`${API_URL}/member/me`, {
    headers: { "X-Device-Token": deviceToken },
  });
};

interface PatchMemberMutationData {
  deviceToken: string;
  notify_email?: boolean;
  notify_sms?: boolean;
  notify_push?: boolean;
}

export const PatchMemberMutationFn = (
  data: PatchMemberMutationData
): Promise<Member> => {
  return patch(`${API_URL}/member/me`, data, {
    headers: { "X-Device-Token": data.deviceToken },
  });
};

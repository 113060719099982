import AsyncStorage from "@react-native-async-storage/async-storage";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import UUID from "react-native-uuid";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { createStackNavigator } from "@react-navigation/stack";
import { NavigationContainer } from "@react-navigation/native";

const Stack = createStackNavigator();

export const Login: React.FC = () => {
  const [deviceUUID, setDeviceUUID] = useState<string>();

  useEffect(() => {
    AsyncStorage.getItem("@expedibox/deviceUUID").then((value) => {
      setDeviceUUID(value || UUID.v4().toString());
    });
  }, []);

  if (!deviceUUID) {
    return <View />;
  }

  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Step1">
        <Stack.Screen
          name="Step1"
          component={Step1}
          options={{
            title: "EXPEDIBIKE",
            headerTitleStyle: {
              fontSize: 30,
              color: "#0D99D6",
              fontWeight: "bold",
            },
            headerTitleAlign: "center",
          }}
        />
        <Stack.Screen
          name="Step2"
          options={{
            title: "",
            headerTitleAlign: "center",
          }}
          component={Step2}
          initialParams={{ deviceUUID }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

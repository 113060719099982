import { useNavigation, useRoute } from "@react-navigation/core";
import React from "react";
import { Text, View, StyleSheet } from "react-native";
import { Button } from "../../components/Button";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useMutation, useQueryClient } from "react-query";
import {
  CreateReservationMutationFn,
  DeleteReservationMutationFn,
} from "../../api/publicApi";
import { useDeviceToken } from "../../components/App/AuthContext";

interface RouteParams {
  reservationId: string;
  dimension: string;
}

export const ConfirmDeleteReservation: React.FC = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const deviceToken = useDeviceToken();
  const queryClient = useQueryClient();
  const { reservationId, dimension } = route.params as RouteParams;

  const mutation = useMutation(DeleteReservationMutationFn, {
    onSuccess: () => {
      queryClient.invalidateQueries("reservations");
      queryClient.invalidateQueries("bikes");
      navigation.navigate("Home");
    },
    onError: () => {},
  });

  return (
    <View style={{ flex: 1, alignItems: "center", marginTop: 20 }}>
      {mutation.error && (
        <Text style={styles.error}>
          Une erreur est survenue lors de l'annulation de la réservation.
        </Text>
      )}

      <MaterialCommunityIcons name="bike-fast" size={44} color="#0D99D6" />
      <Text style={{ marginTop: 20 }}>
        Voulez-vous annuler la réservation de{" "}
        <strong style={{ textTransform: "capitalize" }}>{dimension}</strong>?
      </Text>
      <Button
        style={{ marginTop: 20 }}
        title="Confirmer"
        onPress={() =>
          mutation.mutate({
            deviceToken,
            reservationId,
          })
        }
        disabled={mutation.isLoading}
        loading={mutation.isLoading}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  error: {
    backgroundColor: "#fffbe6",
    marginTop: 15,
    paddingHorizontal: 15,
    paddingVertical: 8,
    textAlign: "center",
    borderWidth: 1,
    borderColor: "#ffecb5",
    color: "#664d03",
    marginBottom: 15,
  },
});

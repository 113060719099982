import React, { useContext } from "react";
import { AuthContext, useDeviceToken } from "../App/AuthContext";
import { Home } from "../../screens/Home";
import { Login } from "../../screens/Login";
import { NavigationContainer, useNavigation } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItem,
} from "@react-navigation/drawer";
import { SelectBike } from "../../screens/SelectBike";
import { ConfirmBike } from "../../screens/ConfirmBike";
import { ConfirmDeleteReservation } from "../../screens/ConfirmDeleteReservation";
import { DrawerActions } from "@react-navigation/native";
import { Linking } from "react-native";
import { MaterialIcons } from "@expo/vector-icons";
import { useInvalidateLogin } from "../../hooks/useInvalidateLogin";
import { useInvalidateLoginOnError } from "../../hooks/useInvalidateLoginOnError";
import { useMutation } from "react-query";
import { DeleteDeviceTokenMutationFn } from "../../api/mobileApi";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

function StackNavigation() {
  const navigation = useNavigation();
  return (
    <Stack.Navigator initialRouteName="Home">
      <Stack.Screen
        name="Home"
        component={Home}
        options={{
          title: "EXPEDIBIKE",
          headerTitleStyle: {
            fontSize: 30,
            color: "#0D99D6",
            fontWeight: "bold",
          },
          headerRight: () => (
            <MaterialIcons
              name="menu"
              size={24}
              color="black"
              style={{ marginRight: 20 }}
              onPress={() => navigation.dispatch(DrawerActions.openDrawer())}
            />
          ),
        }}
      />
      <Stack.Screen
        name="SelectBike"
        options={{
          title: "Sélectionnez un vélo",
          headerTitleAlign: "center",
        }}
        component={SelectBike}
      />
      <Stack.Screen
        name="ConfirmBike"
        options={{ title: "Confirmation", headerTitleAlign: "center" }}
        component={ConfirmBike}
      />
      <Stack.Screen
        name="ConfirmDeleteReservation"
        options={{
          title: "Confirmation de l'annulation",
          headerTitleAlign: "center",
        }}
        component={ConfirmDeleteReservation}
      />
    </Stack.Navigator>
  );
}

function CustomDrawerContent() {
  const authContext = useContext(AuthContext);
  const invalidateLogin = useInvalidateLogin();
  const logoutOnError = useInvalidateLoginOnError("Une erreur est survenue");

  const mutation = useMutation(DeleteDeviceTokenMutationFn, {
    onSuccess: () => invalidateLogin(),
    onError: logoutOnError,
  });

  return (
    <DrawerContentScrollView>
      <DrawerItem
        label="Conditions d'utilisation"
        onPress={() =>
          Linking.openURL("https://expedibox.com/fr/conditions-dutilisation/")
        }
      />
      <DrawerItem
        label="Politique de confidentialité"
        onPress={() =>
          Linking.openURL(
            "https://expedibox.com/fr/politique-de-confidentialite/"
          )
        }
      />
      <DrawerItem
        label="Aide"
        onPress={() => Linking.openURL("https://expedibox.com/fr/contact/")}
      />
      <DrawerItem
        label="Déconnexion"
        style={{ borderTopWidth: 1, borderColor: "#e0e0e0", borderRadius: 0 }}
        onPress={() => mutation.mutate(authContext.deviceToken || "")}
      />
    </DrawerContentScrollView>
  );
}

export const Expedibike: React.FC = () => {
  const deviceToken = useDeviceToken();

  if (!deviceToken) {
    return <Login />;
  }

  return (
    <NavigationContainer>
      <Drawer.Navigator
        drawerContent={() => <CustomDrawerContent />}
        useLegacyImplementation={true}
      >
        <Drawer.Screen
          name="Home"
          options={{ headerShown: false }}
          component={StackNavigation}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
};

import React from "react";
import { ActivityIndicator, StyleProp, ViewStyle } from "react-native";
import styled from "styled-components/native";

const ButtonView = styled.TouchableOpacity`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.theme.info};
  padding-vertical: 10px;
  padding-horizontal: 20px;
  border-radius: 10px;
`;

const Text = styled.Text`
  font-size: 16px;
  color: #fff;
`;

interface Props {
  style?: StyleProp<ViewStyle>;
  onPress?: () => any;
  title?: string;
  disabled?: boolean;
  loading?: boolean;
}

export const Button: React.FC<Props> = ({
  children,
  style,
  onPress,
  title,
  disabled,
  loading,
}) => {
  return (
    <ButtonView style={style} onPress={onPress} disabled={disabled}>
      {loading && (
        <ActivityIndicator
          animating
          size="small"
          color="white"
          style={{ paddingRight: 10 }}
        />
      )}
      {title && <Text>{title}</Text>}
      {children}
    </ButtonView>
  );
};

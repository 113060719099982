import { useNavigation } from "@react-navigation/core";
import React from "react";
import { Text, View, TouchableHighlight } from "react-native";
import { BikeItemProps, styles } from "../SelectBike";

export function BikeItem({ dimension, doors, lockerId }: BikeItemProps) {
  const navigation = useNavigation();
  const available = doors.filter((door) => door.status === "ready");

  return (
    <TouchableHighlight
      activeOpacity={0.6}
      style={{ opacity: available.length ? 1 : 0.5 }}
      underlayColor="#DDDDDD"
      onPress={() => {
        if (available.length)
          navigation.navigate("ConfirmBike", {
            dimension,
            lockerId,
            doorPosition: available[0].position,
          });
      }}
      disabled={!available.length}
    >
      <View style={styles.container}>
        <Text style={{ margin: "auto" }}>
          <strong style={{ textTransform: "capitalize" }}>{dimension}</strong> (
          {available.length} disponible
          {available.length > 1 && "s"})
        </Text>
      </View>
    </TouchableHighlight>
  );
}

import { useEffect, useState } from "react";

/**
 * Simple and naive method to validate an email
 */
export function useIsValidEmail(value: string) {
  const [isValid, setIsValid] = useState(false);
  var emailRegex = /\S+@\S+\.\S+/;

  useEffect(() => {
    if (value && emailRegex.test(value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [value]);

  return isValid;
}

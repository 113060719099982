import React from "react";
import {
  View,
  FlatList,
  StyleSheet,
  RefreshControl,
  ActivityIndicator,
  Text,
} from "react-native";
import { useQuery } from "react-query";
import { Door, GetLockersQueryFn } from "../../api/publicApi";
import { useInvalidateLoginOnError } from "../../hooks/useInvalidateLoginOnError";
import { chain } from "lodash";
import { useDeviceToken } from "../../components/App/AuthContext";
import { BikeItem } from "./screens/BikeItem";

export interface BikeItemProps {
  dimension: string;
  doors: Door[];
  lockerId: string;
}

export const SelectBike: React.FC = () => {
  const logoutOnError = useInvalidateLoginOnError("Une erreur est survenue");
  const deviceToken = useDeviceToken();

  const { isLoading, data, refetch } = useQuery(
    ["bikes", { active: true }],
    () => GetLockersQueryFn(deviceToken),
    { onError: logoutOnError }
  );

  const groups = data?.results.length
    ? chain(data?.results[0].doors)
        .groupBy((door) => door.dimension)
        .map((doors, dimension) => ({
          dimension,
          doors,
          available: doors.filter((door) => door.status === "ready").length,
        }))
        .sortBy([{ available: "desc" }] as any)
        .value()
    : [];

  return (
    <View style={{ flex: 1, alignItems: "center", marginTop: 20 }}>
      {isLoading && (
        <ActivityIndicator animating size="large" color="#0D99D6" />
      )}
      {data && data?.results.length === 0 && (
        <Text>Aucun vélo n'est accessible dans votre compte.</Text>
      )}
      {data && (
        <FlatList
          refreshing={isLoading}
          onRefresh={() => refetch()}
          data={groups}
          keyExtractor={(item) => item.dimension}
          renderItem={({ item }) => (
            <BikeItem
              dimension={item.dimension}
              doors={item.doors}
              lockerId={data.results[0].id}
            />
          )}
          refreshControl={
            <RefreshControl
              refreshing={isLoading}
              tintColor="#0D99D6"
              onRefresh={() => refetch()}
            />
          }
        />
      )}
    </View>
  );
};

export const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 1,
    borderColor: "#e2e2e2",
    flexDirection: "row",
    paddingVertical: 15,
    paddingHorizontal: 16,
    alignItems: "center",
    minWidth: 300,
  },
});
